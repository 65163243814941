import React from 'react'
import { Location } from "@reach/router";
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from './layout'
import SEO from './seo'
import MarTech from "./MarTech";
import PostGrid from "./PostGrid";

function PostTemplate({ data }) {
  const { frontmatter: post } = data.markdownRemark
  const { html: content } = data.markdownRemark
  const { coverPhoto } = data

  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title=""
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `business`]}
          />
        )}
      </Location>

      <MarTech />

      <div className="w-full max-w-2xl mx-auto p-3">
        {coverPhoto.id ? (
          <div
            className="mx-auto"
            style={{ maxWidth: coverPhoto.childImageSharp.original.width }}
          >
            <Img fluid={coverPhoto.childImageSharp.fluid} />
          </div>
        ) : null}
        <h2 className="font-serif text-center">
          {post.title}
        </h2>
        <h5 className="font-sans text-center text-gray-600">
          {post.subtitle}
        </h5>
        <p className="font-sans text-center text-gray-600 text-sm">
          {post.photoCredit}
        </p>
        <div
          className="font-serif text-xl mt-8"
          style={{ wordBreak: 'break-word' }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <h4 className="mt-32">Related Posts</h4>
        {/*
        <PostGrid layout="list" category={post.category} currentPostPath={post.path} />
        */}
        <PostGrid layout="prevnext" category={post.category} currentPostPath={post.path} />
      </div>
      
    </Layout>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query($path: String! $coverPhotoRelativePath: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        path
        title
        subtitle
        photoCredit
        category
      }
    }
    coverPhoto: file(relativePath: {eq: $coverPhotoRelativePath}) {
      id
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          width
        }
      }
    }
  }
`
